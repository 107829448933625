/**
 *
 * GTM DataLayerPush function
 * @param event
 * @param eventCategory
 * @param eventAction
 * @param eventLabel
 * @param formularTyp
 */
export default function dataLayerPush(event, eventCategory, eventAction, eventLabel, formularTyp) {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
        'event': event,
        'eventCategory': eventCategory,
        'eventAction': eventAction,
        'eventLabel': eventLabel,
        'formularTyp': formularTyp
    });
}
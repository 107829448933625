import {$$, $} from '../util/utils';
import dataLayerPush from '../gtm';

var Masonry = require('masonry-layout');
export default function cards() {


    var msnry = new Masonry('.cards-list', {
        itemSelector: '.card-wrapper',
        columnWidth: '.card-size',
    });

    window.addEventListener('resize', function (event) {
        console.log('resize');
        $$(".cards-list .card").forEach(card => {
            const parent = card.closest('.card-wrapper');

            parent.classList.remove('opened');
            card.style.removeProperty("min-height");
            card.style.removeProperty("height");
            parent.style.removeProperty("order");

            setTimeout(function () {
                msnry.layout();
            }, 400)

        });
    });


    $$(".open-icon").forEach(content => {

        content.addEventListener('click', (e) => {
            const parent = e.target.closest('.card-wrapper');
            parent.classList.add('opened');

            const back = parent.querySelector('.card-back');
            const card = parent.querySelector('.card');
            const initHeight = card.offsetHeight;
            const cardTitle = card.querySelector('.title').innerHTML;
            back.style.position = "relative";
            back.style.height = "auto";
            const backHeight = back.offsetHeight;
            back.style.removeProperty("position");
            back.style.removeProperty("height");
            card.style.minHeight = (backHeight > initHeight ? backHeight : initHeight) + "px";

            // var mobile = window.matchMedia("(max-width: 991px)");
            //
            // if (mobile.matches) {
            //     parent.style.order = "-1";
            // }
            setTimeout(function () {
                msnry.layout();
            }, 500)
            dataLayerPush('Generic Event', 'Interaktion', 'Kacheln', cardTitle, '');

        });
    });

    $$(".close-icon").forEach(content => {
        content.addEventListener('click', (e) => {
            const parent = e.target.closest('.card-wrapper');
            parent.classList.remove('opened');
            const card = parent.querySelector('.card');
            card.style.removeProperty("min-height");
            // parent.style.removeProperty("order");
            setTimeout(function () {
                msnry.layout();
            }, 400)
        });
    });

    $$(".stretched-link").forEach(content => {
        content.addEventListener('click', (e) => {
            e.preventDefault();
            const parent = e.target.closest('.card-wrapper'),
                openButton = parent.querySelector('.open-icon'),
                closeButton = parent.querySelector('.close-icon');
            if (parent.classList.contains("opened")) {
                closeButton.click();
            } else {
                openButton.click();
            }
        });
    });

}
export function initResetPasswordForm() {
    const resetPasswordButton = document.getElementById('change-password-submit'),
        form = document.getElementById('password-reset-form');
    if (resetPasswordButton != null && typeof resetPasswordButton !== "undefined") {
        resetPasswordButton.addEventListener("click", function (event) {
            if (!isValidPassword())
                event.preventDefault();
        });
    }
}

function isValidPassword() {
    var isValid = false,
        errorPassword = document.querySelector('resetpassword-passord-error'),
        errorMessage = '';
    const password = document.querySelector('#tx_felogin_pi1-newpassword1'),
        passwordRepeat = document.querySelector('#tx_felogin_pi1-newpassword2');
    if (password.value.length < 10 || passwordRepeat.value.length < 10)
        errorMessage = 'Die Passwortlänge ist zu kurz. Bitte gebe dein neues Passwort zweimal ein.'
    else if (password.value != passwordRepeat.value)
        errorMessage = "Die Passwörter sind nicht gleich, Bitte gebe dein neues Passwort zweimal ein."

    if ((password.value.length < 10 || passwordRepeat.value.length < 10) || (password.value != passwordRepeat.value)) {
        if (!errorPassword) {
            password.parentElement.parentElement.classList.add('error');
            passwordRepeat.parentElement.parentElement.classList.add('error');
            var child = document.createElement("DIV");
            child.classList.add("text-danger");
            child.classList.add("pt-2");
            child.classList.add("resetpassword-passord-error");
            child.innerHTML = errorMessage;
            document.querySelector('.form__message').innerText = child.innerText;
            document.querySelector('.form__message').classList.add('error');
            isValid = false;
        }
    } else {
        password.parentElement.parentElement.classList.remove('error');
        passwordRepeat.parentElement.parentElement.classList.remove('error');
        document.querySelector('.form__message').classList.remove('error');
        isValid = true;
        if (errorPassword) {
            errorPassword.remove();
        }
    }
    return isValid;
}


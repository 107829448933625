export function initUnregisterModal() {
    formartUserBirthday();

    const unregistrationModal = document.getElementById('unregistration-modal'),
        sucessModalCloseButton = document.getElementById('close__sucess__modal');

    unregistrationModal.addEventListener(
        'hide.bs.modal',
        function (event) {
            event.preventDefault();
            event.target.classList.remove("animate__slideInLeft");
            event.target.classList.add("animate__slideOutLeft");
        },
        false
    );
    unregistrationModal.addEventListener(
        'show.bs.modal',
        function (event) {
            event.preventDefault();
            event.target.classList.remove("animate__slideOutLeft");
            event.target.classList.add("animate__slideInLeft");
        },
        false
    );

    sucessModalCloseButton.addEventListener("click", function () {
        window.location.reload(true);

    });

    const deleteAccountButton = document.getElementById('unregistration'),
        logoutButton = document.getElementById('account__logout');
    deleteAccountButton.addEventListener('click', function (e) {
        e.preventDefault();
        const url = e.target.getAttribute('href'),
            xhr = new XMLHttpRequest();
        xhr.responseType = 'json';
        xhr.onload = () => {
            if (xhr.readyState === xhr.DONE) {
                if (xhr.status === 200) {
                    const response = xhr.response;
                    if (response.success === true) {
                        // Logout User
                        logoutButton.click();
                    }
                }
            }
        };
        xhr.open('GET', url);
        xhr.send();
    });
}

function formartUserBirthday() {

    var userBirthDate = document.getElementById('user__birthday');
    if (userBirthDate !== null && typeof userBirthDate !== "undefined") {
        var date = new Date(userBirthDate.innerHTML),
            dd = String(date.getDate()).padStart(2, '0'),
            mm = String(date.getMonth() + 1).padStart(2, '0'),
            yyyy = date.getFullYear(),
            formattedDate = dd + '.' + mm + '.' + yyyy;

        userBirthDate.innerHTML = formattedDate;
    }
}
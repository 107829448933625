//import "./serviceWorker";
import viewport from "./viewport";
import menu from "./menu";
import scrollTo from "./scrollTo";
import slider from "./slider";
import datepicker from "./datepicker";
import {initFormHandler} from './modules/formhandler';
import {initRaffleForm} from './modules/raffleForm';
import {initUnregisterModal} from './modules/modal-unregistration';
import {initAutoRedirectModal} from './modules/modal-redirect'
import {openModalByAction} from './modules/checkAction';
import {openModalOnStart} from './modules/modal-open-start';
import {initCollapse, customCollapse} from './modules/collapse';
import {initInputFilled} from './modules/inputFilled';
import {initButtonHandler} from './modules/buttonHandler';
import {initResetPasswordForm} from './modules/reset-password-form';

import anchorScroll from './modules/anchorScroll.js';
import cards from './modules/cards';
import hero from './modules/hero';
import tooltip from './modules/tooltip';
import navigation from './navigation';
import cta from './cta';
import cconsent from './consent';

viewport();
navigation();
cta();
menu();
scrollTo();
slider();
datepicker();
hero();
//initFormHandler();
//initRaffleForm();
//initUnregisterModal();
anchorScroll();
initAutoRedirectModal();
openModalByAction("forgot", '[data-target="#login-modal"]');
initCollapse();
customCollapse();
initInputFilled();
initButtonHandler();
initResetPasswordForm();
cards();
tooltip();
cconsent();
openModalOnStart('/passwort-vergessen', '[data-target="#login-modal"]');
openModalOnStart('/passwort-erneuern', '[data-target="#login-modal"]');
openModalOnStart('/confirmation', '[data-target="#login-modal"]');
import triangle from '../Svg/triangleRight.svg';

if (process.env.NODE_ENV !== 'production') {
    console.log('Looks like we are in development mode');
}


import {$$, $} from '../util/utils';

export default function tooltip() {

    $$('.ruv-tooltip .ruv-tooltip_icon').forEach((content) => {
        content.addEventListener('click', (e) => {
            const parent = e.target.closest('.ruv-tooltip');
            parent.classList.add('show');

        });
    });
    $$('.ruv-tooltip .tooltip-close').forEach((content) => {
        content.addEventListener('click', (e) => {
            const parent = e.target.closest('.ruv-tooltip');
            parent.classList.remove('show');

        });
    });



    document.addEventListener('click', function (event) {
        if (!event.target.closest('.ruv-tooltip-content-inner') && !event.target.closest('.ruv-tooltip_icon')) {

            $$('.ruv-tooltip').forEach((el) => {
                el.classList.remove('show');
            });
        }
    }, false);

}
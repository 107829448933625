function openModalOnStart(pathName, modalTriggerSelector) {
  if (window.location.pathname.startsWith(pathName)) {
    const modalTrigger = document.querySelector(modalTriggerSelector);

    if (modalTrigger) {
      modalTrigger.Modal.show();
    }
  }
}

export { openModalOnStart };

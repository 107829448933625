
import { tns } from 'tiny-slider/src/tiny-slider';

const initSlider = () => {
	const slider = document.querySelectorAll('.slider'); // container above slider
	slider.forEach(sliderWrapper => {
		const sliderContainer = sliderWrapper.querySelector('.carousel'); // container with slider
		const theSlider = tns({
			container: sliderContainer,
			items: 1,
			slideBy: 'page',
			controlsPosition: 'bottom',
			"swipeAngle": false,
			"speed": 400,
			autoplay: true,
			autoplayButtonOutput: false,
			nav: false,
			"lazyload": true
		});
	});
};

// if (document.querySelectorAll('.slider')) {
// 	initSlider();
// }

export default initSlider;
import {Datepicker} from 'vanillajs-datepicker';

export default function datepicker() {
    const datepickerElement = document.querySelector('.date-picker');

    if (datepickerElement) {
        const datepicker = new Datepicker(datepickerElement, {
            autohide: true,
            buttonClass: 'btn',
            format: 'dd.mm.yyyy',
            language: 'de',
            orientation: 'bottom left'
        });
        datepickerElement.addEventListener('changeDate', function (e, details) {
            if (is_of_age(datepicker.getDate())) {
                datepicker.setDate({clear: true});
            }
        });
    }
}
import {$$, $} from '../util/utils';
import dataLayerPush from '../gtm';

export function initCollapse() {
    $$('.text-collapse').forEach((collapse) => {
        const content = collapse.querySelector('.text-collapse__content');
        const button = collapse.querySelector('.text-collapse__toggle');

        if (content.childNodes.length > 0) {
            const firstChild = content.childNodes[0];
            const text = firstChild.textContent;
            const textLength = text.length;

            if (textLength > 208) {
                content.innerHTML = `<span class='text-collapse__divider'>${text.substr(
                    0,
                    208
                )}...</span>
        <span class='text-collapse__content--hiddentext'>${content.innerHTML}</span>`;
            } else {
                button.classList.add('hidden');
            }
        }
        button.addEventListener('click', (e) => {
            const parent = content.closest('.text-collapse');
            parent.classList.toggle('active');
        });
    });
}

export function customCollapse() {
    $$('.ce-collapse').forEach((collapse) => {
        const content = collapse.querySelector('.collapse-content');
        const button = collapse.querySelector('.collapse-header');
        const collapseTitle = button.querySelector('.collapse-title').innerHTML.trim();

        button.addEventListener('click', (e) => {
            const parent = content.closest('.ce-collapse');


            if (!parent.classList.contains('open')) {
                parent.classList.add('open');
                content.style.maxHeight = content.scrollHeight + 'px';
                dataLayerPush('Generic Event', 'Interaktion', 'Akkordeon geöffnet', collapseTitle, '');
            } else {
                parent.classList.remove('open');
                content.style.maxHeight = '0px';
            }

        });
    });
}
export default function() {
    function inViewport(element) {

        // Get the elements position relative to the viewport

        let bb = element.getBoundingClientRect();

        let bottom = bb.bottom - 80;
        // Check if the element is outside the viewport
        // Then invert the returned value because you want to know the opposite
        return !(bb.top > innerHeight || bottom < 0);

    }

    let myElement = document.querySelector('header.header');
    let boundingClientRect = myElement.getBoundingClientRect();
    if (boundingClientRect.bottom <= 80) {
        document.body.classList.add("outView");
        document.body.classList.remove("inView");
    }

// Listen for the scroll event

    document.addEventListener('scroll', event => {

        // Check the viewport status

        if (inViewport(myElement)) {
            document.body.classList.add("inView");
            document.body.classList.remove("outView");

        } else {
            document.body.classList.remove("inView");
            document.body.classList.add("outView");

        }

    });
}

import { $$ } from '../util/utils';

const focusClass = 'child-input--focus';
const filledClassName = 'input--filled';

/**
 * checks if Input is filled on load and on keydown and adds class for styling
 * @return {void}
 */
export function initInputFilled() {
  $$(
    'input:not([type="hidden"]):not([type="checkbox"]):not([type="radio"]):not([type="select"]):not(.tx-indexedsearch-searchbox-sword), textarea:not([type="hidden"])'
  ).forEach((input) => {
    const isRegistration = input.closest('.js-login-form#registration');
    const isUpdate = input.closest('.js-login-form#confirmUpdate');
    const isRaffle = input.closest('.modal-body-content--raffle');
    const parentGroup =
      isRegistration || isRaffle || isUpdate
        ? input.closest('.form-group')
        : input.closest('.form-group.input-label-overlay');

    if (parentGroup) {
      isFilled(input);
      const label = parentGroup.querySelector('label');
      if (label) {
        label.addEventListener('click', () => input.focus());
      }
      input.addEventListener('keyup', (e) => isFilled(input));
      input.addEventListener('blur', (e) => isFilled(input));
      input.addEventListener('focusin', () => parentGroup.classList.add(focusClass));
      input.addEventListener('focusout', () => parentGroup.classList.remove(focusClass));
    }
  });
  $$('.form-group select').forEach((select) => {
    select.addEventListener('change', () => {
      const selectParent = select.closest('.form-group');
      if (selectParent) {
        if (select.value === '0') {
          selectParent.classList.remove('select-group');
        } else {
          selectParent.classList.add('select-group');
        }
      }
    });
  });
}
function isFilled(input, event) {
  const isRegistration = input.closest('.js-login-form#registration');
  const isUpdate = input.closest('.js-login-form#confirmUpdate');
  const isRaffle = input.closest('.modal-body-content--raffle');
  const parentGroup =
    isRegistration || isRaffle || isUpdate
      ? input.closest('.form-group')
      : input.closest('.form-group.input-label-overlay');

  if (parentGroup) {
    input.classList[input.value ? 'add' : 'remove'](filledClassName);
    parentGroup.classList[input.value ? 'add' : 'remove'](`child-${filledClassName}`);
  }
}

import {Modal} from 'bootstrap.native/dist/bootstrap-native-v4';
import {initInputFilled} from './inputFilled';
import SlimSelect from 'slim-select';
import dataLayerPush from '../gtm';

export function initFormHandler() {
    searchForms();
    searchXhrLinks();
    document.querySelectorAll('input.f3-form-error').forEach((input) => {
        input.addEventListener('change', function (e) {
            input.classList.remove('f3-form-error');
        });
    });
    const loginModal = document.getElementById('login-modal');
    loginModal.addEventListener(
        'hide.bs.modal',
        function (event) {
            const firstForm = event.target.querySelector('form');
            if (
                firstForm &&
                firstForm.id === 'login' &&
                firstForm.classList.contains('from-logout')
            ) {
                window.location.reload(true);
            }
        },
        false
    );
}

function searchForms(ctx) {
    const _loginForm =
        ctx !== undefined && ctx !== null
            ? Array.from(ctx.querySelectorAll('.js-login-form'))
            : Array.from(document.querySelectorAll('.js-login-form'));

    if (_loginForm.length) {
        _loginForm.forEach((form) => {
            if (form.id === 'registration') {
                form.setAttribute("autocomplete", "off");
                form.setAttribute('novalidate', true);
                document.getElementById("registration-anrede_id").firstChild.setAttribute("data-placeholder", "true");
                new SlimSelect({
                    select: '#registration-anrede_id',
                    showSearch: false,
                    placeholder: 'Anrede *',
                });
                trackFormInteractions();
            }
            form.addEventListener('submit', loginHandler);
        });
    }
}

function searchXhrLinks(ctx) {
    const _xhrLinks =
        ctx !== undefined && ctx !== null
            ? ctx.querySelectorAll('[data-xhr-link="true"]')
            : document.querySelectorAll('[data-xhr-link="true"]');
    if (_xhrLinks !== null) {
        _xhrLinks.forEach((el) => {
            el.addEventListener('click', xhrClickHandler);
        });
    }
}

function searchModal(ctx) {
    if (ctx) {
        const _modalLinks = ctx.querySelectorAll('[data-toggle="modal"]');
        _modalLinks.forEach((el) => {
            el.addEventListener('click', function (e) {
                const modalInstance = new Modal(e.target, {});
                modalInstance.show();
            });
        });
    }
}

function loginHandler(e) {
    e.preventDefault();
    e.stopPropagation();

    const url = e.target.getAttribute('action');
    const xhr = new XMLHttpRequest();
    const formData = new FormData(e.target);
    let modalContent = e.target.closest('.modal-body-content');
    if (e.target.id === 'registration') {
        trackRegistrationSubmit(e);
        if (isValidRegistration()) {
            modalContent.classList.add('loading');
            xhr.open('POST', url);
            xhr.send(formData);
        } else {
            document.getElementById('form-message').scrollIntoView({
                behavior: "smooth",
                block: "end",
                inline: "nearest"
            });
        }
    } else {
        modalContent.classList.add('loading');
        xhr.open('POST', url);
        xhr.send(formData);
    }
    xhr.onload = function () {

        if (e.target.id === 'registration') {

            if (isValidRegistration()) {
                const newDiv = document.createElement('div');
                newDiv.innerHTML = xhr.responseText;

                if (
                    !Boolean(
                        newDiv
                            .querySelector('#registration-modal')
                            .querySelector('.modal-body-content ul')
                    )
                ) {
                    window.location.assign(xhr.responseURL);
                    return;
                }

                const messageDiv = document.createElement('div');
                messageDiv.classList.add('form__message');
                messageDiv.classList.add('form__message--error');
                messageDiv.innerHTML = newDiv
                    .querySelector('#registration-modal')
                    .querySelector('.modal-body-content ul').outerHTML;

                modalContent.insertBefore(messageDiv, modalContent.querySelector('*'));
                modalContent.scrollTop = 0;

            }
        } else if (e.target.id === 'login') {
            dataLayerPush('Generic Event', 'Account', 'Login', 'Attempt', '');
            if (xhr.responseText.indexOf('error-login') < 0) {
                dataLayerPush('Generic Event', 'Account', 'Login', 'Success', '');
                window.location.reload(true);
                return;
            }
            dataLayerPush('Generic Event', 'Account', 'Login', 'Fail', '');
            modalContent.innerHTML = xhr.responseText;
        } else if (e.target.id === 'logout') {
            modalContent.innerHTML = xhr.responseText;
            const firstForm = modalContent.querySelector('form');
            if (firstForm) {
                firstForm.classList.add('from-logout');
            }
        } else if (e.target.id === 'confirmUpdate') {
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                'event': virtPath,
                'pagePath': '/registrierung/doi',
                'title': 'Registrierung DOI'
            });

            dataLayerPush('Generic Event', 'Account', 'Registrierung', 'Attempt', '');
            if (xhr.responseURL.search('tx_addressbox_userconfirmupdate') === -1) {
                dataLayerPush('Generic Event', 'Account', 'Registrierung', 'Success', '');
                window.location.assign(xhr.responseURL);
                return;
            } else {
                dataLayerPush('Generic Event', 'Account', 'Registrierung', 'Fail', '');
                modalContent = e.target.closest('.tx-addressbox .confirm-modal');
                var parser = new DOMParser(),
                    doc = parser.parseFromString(xhr.responseText, 'text/html');

                modalContent.innerHTML = doc.body.getElementsByClassName('custom-modal')[0].innerHTML;
            }
        } else {
            modalContent.innerHTML = xhr.responseText;
        }
        searchForms(modalContent);
        searchXhrLinks(modalContent);
        searchModal(modalContent);
        initInputFilled();
        modalContent.classList.remove('loading');
    };
}

function xhrClickHandler(e) {
    e.stopPropagation();
    e.preventDefault();
    const source = e.target.getAttribute('href');
    if (e.target.dataset.xhrLink) {
        updateModal(source, e.target);
    }
}

function updateModal(url, element, onBefore, onAfter) {
    const xhr = new XMLHttpRequest();
    const modalContent = element.closest('.modal-body-content');

    modalContent.classList.add('loading');
    xhr.open('GET', url);
    xhr.send();
    xhr.onload = () => {
        modalContent.innerHTML = xhr.responseText;
        searchForms(modalContent);
        searchXhrLinks(modalContent);
        searchModal(modalContent);
        initInputFilled();
        modalContent.classList.remove('loading');
    };
}

function updateUserStatus(loggedIn) {
    const _userLink = document.querySelector('user-link');
    loggedIn
        ? _userLink.classList.add('user-link--logged-in')
        : _userLink.classList.remove('user-link--logged-in');
}

function acceptBirthday(birthday) {
    if (birthday.length !== 10) {
        return false;
    }
    var dmy = birthday.split(".");

    var bithdayDate = new Date(dmy[2], dmy[1] - 1, dmy[0]);
    var yearsDifference = yearsDiff(bithdayDate);
    if (!isValidDate(bithdayDate) || (dmy[1] > 12)) {
        return false;
    }
    if (yearsDifference < 18) {
        return false;
    }
    return true;
}

function yearsDiff(bithdayDate) {
    let date1 = new Date(bithdayDate);
    let date2 = new Date();
    let yearsDifference = date2.getFullYear() - date1.getFullYear();
    return yearsDifference;
}

function isValidDate(dateObject) {
    return new Date(dateObject).toString() !== 'Invalid Date';
}

function isValidGender() {
    let isValid = false,
        gender = document.querySelector('#registration-anrede_id').value,
        errorDiv = document.querySelector('.registration-gender-repeat-error');
    if (0 == gender) {
        document.querySelector('#registration-anrede_id').nextSibling.classList.add('error');
        if (!errorDiv) {
            var child = document.createElement("DIV");
            child.classList.add("text-danger");
            child.classList.add("pt-2");
            child.classList.add('registration-gender-repeat-error');
            child.innerHTML = 'Bitte füllen Sie dieses Feld aus. (Anrede)';
            trackFormErrorMessages(child.innerHTML);
            document.getElementById('form-message').append(child);
            isValid = false;
        }
    } else {
        document.querySelector('#registration-anrede_id').nextSibling.classList.remove('error');
        if (errorDiv) {
            errorDiv.remove();
        }
        isValid = true;
    }
    return isValid;
}


function isFieldEmpty(field, fieldName, fieldDescription) {
    var isEmpty = false;
    var errorDiv = document.querySelector('.registration-' + fieldName + '-repeat-error');
    if (!field.value.length) {
        if (!errorDiv) {
            field.parentElement.parentElement.classList.add('error');
            var child = document.createElement("DIV");
            child.classList.add("text-danger");
            child.classList.add("pt-2");
            child.classList.add('registration-' + fieldName + '-repeat-error');
            child.innerHTML = 'Bitte füllen Sie dieses Feld aus. ( ' + fieldDescription + ' )';
            trackFormErrorMessages(child.innerHTML);
            document.getElementById('form-message').append(child);
            isEmpty = true;
        }
    } else {
        if (errorDiv) {
            field.parentElement.parentElement.classList.remove('error');
            errorDiv.remove();
        }
        isEmpty = false;
    }
    return isEmpty;
}

function isValidEmailFormat(field, fieldName) {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    var errorDiv = document.querySelector('.registration-' + fieldName + '-repeat-error'),
        isValid = re.test(field.value.toLowerCase());

    if (!isValid) {
        if (!errorDiv) {
            field.parentElement.parentElement.classList.add('error');
            var child = document.createElement("DIV");
            child.classList.add("text-danger");
            child.classList.add("pt-2");
            child.classList.add('registration-' + fieldName + '-repeat-error');
            child.innerHTML = 'Dies ist keine gültige Mailadresse.';
            trackFormErrorMessages(child.innerHTML);
            document.getElementById('form-message').append(child);
        }
    } else {
        if (errorDiv) {
            field.parentElement.parentElement.classList.remove('error');
            errorDiv.remove();
        }
    }
    return isValid;
}

function isValidEmail() {
    var isValid = false,
        errorMail = document.querySelector('.registration-email-repeat-error');
    const registrationEmailAddress = document.querySelector('#registration-email-address'),
        registrationEmailRepeat = document.querySelector('#registration-email-repeat');
    if (registrationEmailAddress.value.length && registrationEmailRepeat.value.length) {
        if (registrationEmailAddress.value != registrationEmailRepeat.value) {
            if (!errorMail) {
                registrationEmailAddress.parentElement.parentElement.classList.add('error');
                registrationEmailRepeat.parentElement.parentElement.classList.add('error');
                var child = document.createElement("DIV");
                child.classList.add("text-danger");
                child.classList.add("pt-2");
                child.classList.add("registration-email-repeat-error");
                child.innerHTML = 'Die E-Mail Adressen sind nicht identisch.';
                trackFormErrorMessages(child.innerHTML);
                document.getElementById('form-message').append(child);
                isValid = false;
            }
        } else {
            registrationEmailAddress.parentElement.parentElement.classList.remove('error');
            registrationEmailRepeat.parentElement.parentElement.classList.remove('error');
            isValid = true;
            if (errorMail) {
                errorMail.remove();
            }
        }
    }
    return isValid;
}

function isValidBirthday() {
    var errorBirthday = document.querySelector('.registration-birthday-error'),
        registrationBirthday = document.querySelector('#registration-birthday'),
        isValid = false;

    if (!acceptBirthday(registrationBirthday.value)) {
        if (!errorBirthday) {
            registrationBirthday.parentElement.parentElement.classList.add('error');
            var child2 = document.createElement("DIV");
            child2.classList.add("text-danger");
            child2.classList.add("pt-2");
            child2.classList.add("registration-birthday-error");
            child2.innerHTML = 'Du musst mindestens 18 Jahre alt sein.';
            trackFormErrorMessages(child2.innerHTML);
            document.getElementById('form-message').append(child2);
            isValid = false;
        }
    } else {
        registrationBirthday.parentElement.parentElement.classList.remove('error');
        isValid = true;
        if (errorBirthday) {
            errorBirthday.remove();
        }
    }
    return isValid;
}


function isValidRegistration() {
    var isValid = false;

    const firstName = document.querySelector('#registration-first_name'),
        lastName = document.querySelector('#registration-last_name'),
        registrationBirthday = document.querySelector('#registration-birthday'),
        registrationEmailAddress = document.querySelector('#registration-email-address'),
        registrationEmailRepeat = document.querySelector('#registration-email-repeat');


    if (isValidGender() & !isFieldEmpty(firstName, 'firstname', 'Vorname') & !isFieldEmpty(lastName, 'lastname', 'Nachname') & !isFieldEmpty(registrationEmailAddress, 'emailadress', 'E-Mail-Adresse') & !isFieldEmpty(registrationEmailRepeat, 'emailRepeat', 'E-Mail-Adresse wiederholung') & isValidEmailFormat(registrationEmailAddress, 'emailadress') & isValidEmailFormat(registrationEmailRepeat, 'emailRepeat') & !isFieldEmpty(registrationBirthday, 'birthday', 'Geburtsdatum') & isValidEmail() & isValidBirthday() & isValidReCaptcha()) {
        document.getElementById('form-message').classList.remove('error');
        isValid = true;

    } else {
        document.getElementById('form-message').classList.add('error');
        isValid = false;
    }
    return isValid;
}

function isCaptchaChecked() {
    return grecaptcha && grecaptcha.getResponse().length !== 0;
}

function isValidReCaptcha() {
    var errorDiv = document.querySelector('.registration-recaptcha-error'),
        isValid = isCaptchaChecked(),
        recaptchaInput = document.querySelector('.g-recaptcha');

    if (!isValid) {
        if (!errorDiv) {
            recaptchaInput.classList.add('error');
            var child = document.createElement("DIV");
            child.classList.add("text-danger");
            child.classList.add("pt-2");
            child.classList.add('registration-recaptcha-error');
            child.innerHTML = 'Recaptcha-Validierungsfehler.';
            trackFormErrorMessages(child.innerHTML);
            document.getElementById('form-message').append(child);
        }
    } else {
        if (errorDiv) {
            recaptchaInput.classList.remove('error');
            errorDiv.remove();
        }
    }
    return isValid;
}

function trackRegistrationSubmit(e) {
    var label = e.submitter.innerHTML;
    dataLayerPush('Generic Event', 'Interaktion', 'CTA', 'Newsletter-Anmeldung', 'Newsletter');
}

function trackFormInteractions() {

    var salutation = document.getElementById('registration-anrede_id'),
        firstname = document.getElementById('registration-first_name'),
        lastname = document.getElementById('registration-last_name'),
        email = document.getElementById('registration-email-address'),
        emailRepeat = document.getElementById('registration-email-repeat'),
        birthday = document.getElementById('registration-birthday');

    salutation.onchange = function () {
        dataLayerPush('Generic Event', 'Interaktion', 'Select', 'Anrede', 'Newsletter');
    }
    firstname.onclick = function () {
        dataLayerPush('Generic Event', 'Interaktion', 'Texteingabefeld', 'Vorname', 'Newsletter');
    }
    lastname.onclick = function () {
        dataLayerPush('Generic Event', 'Interaktion', 'Texteingabefeld', 'Nachname', 'Newsletter');
    }
    email.onfocus = function (e) {
        e.preventDefault();
        dataLayerPush('Generic Event', 'Interaktion', 'Texteingabefeld', 'E-Mail', 'Newsletter');
    }
    emailRepeat.onfocus = function (e) {
        e.preventDefault();
        dataLayerPush('Generic Event', 'Interaktion', 'Texteingabefeld', 'E-Mail-Wiederholung', 'Newsletter');
    }
    birthday.onclick = function (e) {
        e.preventDefault();
        dataLayerPush('Generic Event', 'Interaktion', 'Texteingabefeld', 'Geburtsdatum', 'Newsletter');
    }
}

function trackFormErrorMessages(message) {
    dataLayerPush('Generic Event', 'Fehlermeldung', 'Newsletter-Anmeldung', message, 'Newsletter');
}

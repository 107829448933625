import dataLayerPush from './gtm';

export default function navigation() {
    var navItems = document.querySelectorAll(".navbar .nav-item .nav-link:not(#login-modal-link)");
    var footerNavItems = document.querySelectorAll(".footer .footer__link li a");
    var title = '';
    trackNavItem(navItems, 'Hauptnavigation');
    trackNavItem(footerNavItems, 'Footer');

    function trackNavItem(navItems, eventAction) {
        for (var i = 0; i < navItems.length; i++) {
            navItems[i].onclick = function (e) {
                if (eventAction == 'Footer')
                    title = this.innerHTML.trim();
                else
                    title = this.getAttribute("title");
                console.info(title);
                dataLayerPush('Generic Event', 'Navigation', eventAction, title, '');
            };
        }
    }
}
export function initButtonHandler() {
    const registerLink = document.getElementById('registration-modal-link'),
        loginLink = document.getElementById('login-modal-link'),
        forgotPasswordLink = document.getElementsByClassName('forgot-password')[0],
        logoutLink = document.getElementsByClassName('account__logout')[0],
        openRegistrationModalButton = document.getElementsByClassName('open__register__modal')[0],
        forgotPasswordModalButton = document.getElementsByClassName('open__forgotPassword__modal')[0],
        logoutBtn = document.getElementsByClassName('logout__btn')[0];

    if (typeof openRegistrationModalButton !== "undefined") {
        openRegistrationModalButton.addEventListener('click', function (event) {
            event.preventDefault();
            if (registerLink != null && typeof registerLink !== "undefined")
                registerLink.click();
            else
                loginLink.click();
        });
    }
    if (typeof forgotPasswordModalButton !== "undefined") {
        forgotPasswordModalButton.addEventListener('click', function (event) {
            event.preventDefault();
            if (forgotPasswordLink != null && typeof forgotPasswordLink !== "undefined") {
                forgotPasswordLink.click();
                loginLink.click();
            }
        });
    }
    if (typeof logoutBtn !== "undefined") {
        logoutBtn.addEventListener('click', function (event) {
            event.preventDefault();
            if (logoutLink != null && typeof logoutLink !== "undefined") {
                loginLink.click();
                logoutLink.click();
            }
        });
    }
}
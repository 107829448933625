import {$$, $} from '../util/utils';

export default function anchorScroll() {
    const el = $$('.ce-stage a.jumpMark')[0];
    if (el != null && typeof el !== "undefined") {
        el.addEventListener('click', function (e) {
            event.preventDefault();
            var target = el.href.substring(el.href.indexOf("#") + 1),
                elementPosition = document.getElementById(target).offsetTop - 80;
            window.scrollTo({
                top: elementPosition,
                behavior: "smooth"
            });
        });
    }
}
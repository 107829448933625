import {$$, $} from '../util/utils';

export default function hero() {
    const tooltip_1 = document.getElementById("tooltip_1");
    const tooltip_2 = document.getElementById("tooltip_2");

    $$('.ruv-tooltip_icon.tooltip_1').forEach((content) => {
        content.addEventListener('click', (e) => {
            tooltip_1.classList.add('show');
        });
    });

    $$('.ruv-tooltip_icon.tooltip_2').forEach((content) => {
        content.addEventListener('click', (e) => {
            tooltip_2.classList.add('show');
        });
    });
}
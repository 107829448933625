import dataLayerPush from '../gtm';

export function initRaffleForm() {
    initForm();
    updatePopUpLogin();
    openRaffleForm();
}

function initForm(ctx) {
    const _raffleForm =
        ctx !== undefined && ctx !== null
            ? Array.from(ctx.querySelectorAll('.raffle-form'))
            : Array.from(document.querySelectorAll('.raffle-form'));

    if (_raffleForm.length) {
        _raffleForm.forEach((form) => {
            form.setAttribute('novalidate', true);
            form.setAttribute("autocomplete", "off");
            form.addEventListener('submit', formHandler);
            imageUploadClickHandler();
        });
    }

    /**In case Col-left Haqs only one item */
    if (document.querySelectorAll('.col-left fieldset').length == 1) {
        document.getElementsByClassName('col-left')[0].classList.add('single-field');
    }

    /**In case only Col-right Exist */
    if (document.querySelectorAll('.col-left fieldset').length == 0 && document.querySelectorAll('.col-right fieldset').length > 0) {
        document.getElementsByClassName('col-right')[0].classList.add('single-field');
    }

    /**In case only one field in the Form Image Upload */
    if (document.querySelectorAll('.col-right fieldset').length == 0 &&
        document.querySelectorAll('.col-left fieldset').length == 1 && document.querySelectorAll('.col-left .img-upload-wrapper').length == 1) {
        document.getElementsByClassName('raffle-form')[0].classList.add('only-upload')
    }
}

function formHandler(e) {
    e.preventDefault();
    e.stopPropagation();

    trackRaffleForm(e);
    const url = e.target.getAttribute('action'),
        xhr = new XMLHttpRequest(),
        formData = new FormData(e.target);

    var modalContent = e.target.closest('.modal-body-content--raffle');

    modalContent.classList.add('loading');
    xhr.open('POST', url);
    xhr.send(formData);
    xhr.onload = function () {
        var newDiv = document.createElement('div');
        newDiv.innerHTML = xhr.responseText;
        if (!Boolean(newDiv.querySelector('.modal-body-content--raffle'))) {
            window.location.assign(xhr.responseURL);
            return;
        }
        modalContent.innerHTML = newDiv.querySelector('.modal-body-content--raffle').innerHTML;
        initRaffleForm();
        modalContent.classList.remove('loading');
    };
}

function imageUploadClickHandler() {
    const inputs = document.querySelectorAll('.img-upload-input'),
        previewImageDivWrapper = document.getElementById('preview-image-wrapper'),
        previewImage = document.getElementById('image-upload-placeholder');
    if (typeof (previewImage) !== undefined && previewImage !== null) {
        const helperText = previewImageDivWrapper.nextElementSibling;
        var fileName = '';
        if (typeof (inputs) !== undefined && inputs !== null) {
            Array.prototype.forEach.call(inputs, function (input) {
                if (previewImage !== null) {
                    input.addEventListener('change', function (e) {

                        if (input.files && input.files[0]) {
                            document.getElementById('preview-image-wrapper').classList.add('show-icons');
                            fileName = this.files[0].name;
                            var reader = new FileReader();
                            reader.onload = function (e) {
                                previewImage.setAttribute('src', e.target.result);
                            }
                            if (previewImage.classList.contains('d-none')) {
                                previewImage.classList.remove('d-none');
                            }
                            if (fileName && helperText !== null) {
                                helperText.innerHTML = fileName;
                                helperText.classList.add("image-preview-text");
                            }
                            reader.readAsDataURL(input.files[0]);
                        }
                    });
                }
            });
        }
    }
}


function updatePopUpLogin() {
    const isRaffletn = document.getElementsByClassName('raffle-form-btn')[0];
    if (isRaffletn) {
        isRaffletn.addEventListener('click', function (event) {
            let notLogged = this.getAttribute('data-loggedin');
            if (notLogged) {
                document.cookie = 'popUpRaffleForm=1';
            }
        });
    }
}

function openRaffleForm() {
    var open = getCookie('popUpRaffleForm');
    const isRaffletn = document.getElementsByClassName('raffle-form-btn')[0];
    if (open) {
        if (typeof (isRaffletn) !== "undefined") {
            isRaffletn.click();
            document.cookie = 'popUpRaffleForm=; expires=Thu, 01 Jan 1970 00:00:00 UTC;';
        }
    }
}

/**Get Cookies Access Name**/
function getCookie(name) {
    // Add the = sign
    name = name + '=';

    // Get the decoded cookie
    var decodedCookie = decodeURIComponent(document.cookie);

    // Get all cookies, split on ; sign
    var cookies = decodedCookie.split(';');

    // Loop over the cookies
    for (var i = 0; i < cookies.length; i++) {
        // Define the single cookie, and remove whitespace
        var cookie = cookies[i].trim();

        // If this cookie has the name of what we are searching
        if (cookie.indexOf(name) == 0) {
            // Return everything after the cookies name
            return cookie.substring(name.length, cookie.length);
        }
    }
}

function trackRaffleForm(e) {
    var label = e.submitter.innerHTML;
    dataLayerPush('Generic Event', 'Interaktion', 'CTA', label + ' - Gewinnspiel', '');
}
function openModalByAction(action, modalTriggerSelector) {
    if (window.location.href.search(action) != -1) {
        const modalTrigger = document.querySelector(modalTriggerSelector);

        if (modalTrigger) {
            modalTrigger.Modal.show();
        }
    }
}

export { openModalByAction };

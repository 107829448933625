import dataLayerPush from './gtm';

export default function cta() {
    var buttons = document.querySelectorAll(".bodytext .btn:not(.logout__btn)"),
        logoutButton = document.getElementsByClassName("logout__btn")[0];

    for (var i = 0; i < buttons.length; i++) {
        buttons[i].onclick = function (e) {
            dataLayerPush('Generic Event', 'Interaktion', 'CTA', this.innerHTML.trim(), '');
        };
    }
    if (typeof logoutButton !== "undefined") {
        logoutButton.addEventListener('click', function (event) {
            dataLayerPush('Generic Event', 'Account', 'Logout', undefined, '');
        });
    }
}
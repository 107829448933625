import {$$, $} from '../util/utils';

export function initAutoRedirectModal() {
    const autoRedirectLink = document.getElementById('redirect-modal-link'),
        targetLink = document.getElementById('modal_target_link');

    $$('.open__autoRedirect__modal').forEach((button) => {
        button.addEventListener('click', (e) => {
            e.preventDefault();
            const location = e.target.getAttribute('href');
            targetLink.href = location;
            if (autoRedirectLink != null && typeof autoRedirectLink !== "undefined") {

                autoRedirectLink.click();
                setTimeout(() => {
                    window.location.assign(location);
                }, 5000);
            }
        });
    });
}